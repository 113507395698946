@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
* {
  font-family: "Rubik", sans-serif;
}

.fend {
  /* width:100vw; */
  height: 60vh;

  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;

  font-family: "Lato";
  font-style: normal;
  /* margin-top: 3vh; */
}
.thin-yellow-line {
  margin-bottom: 2vh;
  width: 100%;
  height: 0.4vh;
  background-color: #023b7d;
}
.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 24px;
  margin: 0 10px;
}
