.search-bars2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4vh;
  }
  
  .search-bar2 {
    height: 5vh;
    width:40vw;
    padding: 10px;
    margin: 10px;
    font-size: 2rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none; /* Remove default outline */
    
  }
  
  .search-bar3 {
    height: 12vh;
    width:40vw;
    padding: 10px;
    margin: 10px;
    font-size: 2rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none; /* Remove default outline */
    
  }